html, body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background: #f6f6fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('assets/fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */

/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('assets/fonts//montserrat-v14-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
       url('assets/fonts//montserrat-v14-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('assets/fonts//montserrat-v14-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('assets/fonts//montserrat-v14-latin-800.woff') format('woff'), /* Modern Browsers */
       url('assets/fonts//montserrat-v14-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('assets/fonts//montserrat-v14-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}